import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import ReactHelmet from 'react-helmet';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  font-family: 'DM Sans', sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h3 {
    font-size: 30px;
  }
`;

export default (props) => {
  return (
    <>
      <ReactHelmet>
        <title>Solegito - Strona w budowie</title>
      </ReactHelmet>
      <Container>
        <h3>Strona w przebudowie</h3>
        <Img fixed={props.data.logoDark.childImageSharp.fixed} />
      </Container>
    </>
  )
};

export const query = graphql`
  query {
    logoDark: file(relativePath: {eq: "solegito-logo-dark.png"}) {
      childImageSharp {
        fixed(width: 120, height: 12) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`